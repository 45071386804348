import React from 'react';
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import moment from 'moment';

const MultiTableExcelExport = ({ xlsxfilename, data1, data2, data3 , title, date1, date2, date3, motorP1, motorP2}) => {

    const handleExport = async () => {
        try {
            // Step 1: Create a workbook and a worksheet
            const workbook = new ExcelJS.Workbook();
            const worksheet = workbook.addWorksheet('Combined Report');

            const motorRowP1 = {
                name: 'Motor',
                previous_unit: motorP1?.previous_unit || '',
                total_unit: motorP1?.total_unit || '',
                present_unit: motorP1?.unit || '',
                unit_amount: motorP1?.unit_amount || '',
                motor_bill: motorP1?.division || '',
                total_amount: '', // Assuming no value for Total
                paid_amount: '', // Assuming no value for Paid
                paid_date: '', // Assuming no value for P_Date
            };
            const motorRowP2 = {
                name: 'Motor',
                total_unit: motorP2?.total_unit || '',
                previous_unit: motorP2?.previous_unit || '',
                present_unit: motorP2?.unit || '',
                unit_amount: motorP2?.unit_amount || '',
                motor_bill: motorP2?.division || '',
                total_amount: '', // Assuming no value for Total
                paid_amount: '', // Assuming no value for Paid
                paid_date: '', // Assuming no value for P_Date
            };
    
            // Append motor rows to data1 and data2
            const modifiedData1 = [...data1, motorRowP1];
            const modifiedData2 = [...data2, motorRowP2];


            // Function to add a table to the worksheet
            const addTableToSheet = (worksheet, title, date, columns, data, startRow) => {
                // Add title row
                const titleText = `${title} - (${moment(date).format('D MMMM YYYY')})`;
                const titleRow = worksheet.getRow(startRow);
                titleRow.getCell(1).value = titleText;
                titleRow.getCell(1).font = { size: 16, bold: true };
                titleRow.getCell(1).alignment = { horizontal: 'center', vertical: 'middle' };
                titleRow.getCell(1).fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'FF000000' }, // Black background for title row
                };
                titleRow.getCell(1).font = {
                    size: 15,
                    bold: true,
                    color: { argb: 'FFFFFFFF' } // White text
                };
                worksheet.mergeCells(startRow, 1, startRow, columns.length);

                // Add header row
                const headerRow = worksheet.getRow(startRow + 1);
                columns.forEach((col, index) => {
                    headerRow.getCell(index + 1).value = col.header;
                    headerRow.getCell(index + 1).font = { bold: true, size: 12 };
                    headerRow.getCell(index + 1).alignment = { horizontal: 'center', vertical: 'middle',  wrapText: true };
                    headerRow.getCell(index + 1).fill = {
                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: 'FFCCCCCC' }, // Light grey background
                    };
                    headerRow.getCell(index + 1).border = {
                        top: { style: 'thin' },
                        left: { style: 'thin' },
                        bottom: { style: 'thin' },
                        right: { style: 'thin' },
                    };
                });

                // Add data rows
                data.forEach((item, rowIndex) => {
                    const dataRow = worksheet.getRow(startRow + 2 + rowIndex);
                    dataRow.height = 23; 
                    columns.forEach((col, colIndex) => {
                        const cell = dataRow.getCell(colIndex + 1);
                        cell.value = item[col.key] || '';
                        cell.alignment = { horizontal: 'center', vertical: 'middle' };
                        cell.font={size:12};
                          // Apply background colors for specific columns in data
                          if (col.key === 'name') {
                            cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FF90EE90' } }; // Green for FlatNo
                            cell.font = { bold: true };
                        } else if (col.key === 'total_amount') {
                            cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFFFFF00' } }; // Yellow for Total
                            cell.font = { bold: true };
                        }
                        cell.border = {
                            top: { style: 'thin' },
                            left: { style: 'thin' },
                            bottom: { style: 'thin' },
                            right: { style: 'thin' },
                        };
                    });
                });

                // Return the row number after the last data row
                return startRow + 2 + data.length;
            };

            // Define columns and data for each table
            const tables = [
                {
                    title: 'Ahmad Plaza',
                    date: new Date(date1),
                    columns: [
                        { header: 'FlatNo', key: 'name' },
                        { header: 'Total Unit', key: 'total_unit' },
                        { header: 'Prev Unit', key: 'previous_unit' },
                        { header: 'Units' , key: "present_unit"},
                        { header: 'Amount' , key: "unit_amount"},
                        { header: 'Motor Bill' , key: "motor_bill"},
                        { header: 'Prev Bal' , key: "previous_balance"},
                        { header: 'Total' , key: "total_amount"},
                        { header: 'Paid' , key: "paid_amount"},
                        { header: 'Paid Date' , key: "paid_date"},
                    ],
                    data: modifiedData1,
                    customWidth: 9,
                },
                {
                    title: 'Ahsan Plaza',
                    date: new Date(date2),
                    columns: [
                        { header: 'FlatNo', key: 'name' },
                        { header: 'Total Unit', key: 'total_unit' },
                        { header: 'Prev Unit', key: 'previous_unit' },
                        { header: 'Rate' , key: "rate_per_unit"},
                        { header: 'Units' , key: "present_unit"},
                        { header: 'Amount' , key: "unit_amount"},
                        { header: 'Motor Bill' , key: "motor_bill"},
                        { header: 'Prev Bal' , key: "previous_balance"},
                        { header: 'Total' , key: "total_amount"},
                        { header: 'Paid' , key: "paid_amount"},
                    ],
                    data: modifiedData2,
                    customWidth: 9,
                },
                {
                    title: 'Ikram Plaza',
                    date: new Date(date3),
                    columns: [
                        { header: 'FlatNo', key: 'name' },
                        { header: 'Total Unit', key: 'total_unit' },
                        { header: 'Prev Unit', key: 'previous_unit' },
                        { header: 'Units' , key: "present_unit"},
                        { header: 'Rate' , key: "rate_per_unit"},
                        { header: 'Amount' , key: "unit_amount"},
                        { header: 'Prev Bal' , key: "previous_balance"},
                        { header: 'Total' , key: "total_amount"},
                        { header: 'Paid' , key: "paid_amount"},
                        { header: 'Paid Date' , key: "paid_date"},
                    ],
                    data: data3,
                    customWidth: 9,
                }
            ];

            // Step 2: Add each table to the worksheet in sequence
            let currentRow = 1; // Start at the first row
            tables.forEach((table) => {
                currentRow = addTableToSheet(
                    worksheet,
                    table.title,
                    table.date,
                    table.columns,
                    table.data,
                    currentRow
                );
                currentRow += 2; // Add some space between tables
            });

            // Adjust columns width
            worksheet.columns = worksheet.columns.map((col) => ({ width: 9 }));

            // Step 3: Generate and download the Excel file
            const buffer = await workbook.xlsx.writeBuffer();
            saveAs(new Blob([buffer]), `${xlsxfilename}.xlsx`);
        } catch (error) {
            console.error('Error generating Excel file:', error);
        }
    };

    return (
        <button className="btn btn-success py-2 px-4" onClick={handleExport}>
            Export to Excel
        </button>
    );
};

export default MultiTableExcelExport;
